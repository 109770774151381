import React from 'react';
import Chart from 'react-apexcharts';

class CategoryChart extends React.Component {
    render() {
        if (!Array.isArray(this.props.data)) return (
            <div>Virheellinen tietorakenne.</div>
        );

        const data = this.props.data;

        const options = {
            labels: data.map(item => item.label),
            chart: {
                animations: {
                    enabled: false
                },
            }
        };

        const series = data.map(item => item.value);

        return (
            <>
                <Chart
                    options={options}
                    series={series}
                    type="donut"
                />
            </>
        )
    }
}

export default CategoryChart;
