import React from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import './assets/sass/app.scss';
import ResultsTable from "./components/ResultsTable";
import CategoryFilter from "./components/CategoryFilter";
import { connect } from "react-redux";
import KeywordFilter from "./components/KeywordFilter";
import Collection from "./helpers/Collection";
import { Card, Col, Row } from "react-bootstrap";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        axios.get('data/index.json').then(({ data }) => {
            let requests = [];

            data.forEach((profile) => {
                requests.push(axios.get('data/profiles/' + profile));
            });

            axios.all(requests).then(responses => {
                responses.forEach(({ data }) => {
                    this.props.store.dispatch({
                        type: "ADD_PUBLISHER",
                        data: data,
                    });
                });

                this.setState({
                    loading: false
                });
            });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={ {
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                } } className="text-center">
                    <h1 style={ { fontSize: "300%" } }>
                        <FontAwesomeIcon icon={ faSpinner } spin className="mr-1"/>
                        Ladataan...
                    </h1>
                </div>
            )
        }

        const publishers = this.props.publishers;
        const activeKeywords = this.props.activeKeywords;

        return (
            <Container>
                <Row className="pt-5">
                    <Col>
                        <h1>Haluatko kirjailijaksi?</h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className="lead">
                            Tervetuloa kustantamohakuun! Voit etsiä kustantamoa kategorian tai avainsanan perusteella,
                            tai yhdistellä useita hakuja löytääksesi käsikirjoituksellesi sopivimmat kustantajat.
                            Klikkaa kustantamon nimeä nähdäksesi kustantamon julkaisuprofiilin. Onnea etsintään!
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col className="my-3">
                        <CategoryFilter store={ this.props.store }/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <KeywordFilter store={ this.props.store }/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ResultsTable publishers={ publishers } activeKeywords={ activeKeywords }/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card bg="dark mb-3 mt-5 text-white">
                            <Card.Body className="pt-5 pb-4 text-center">
                                <p>
                                    Tekijä Ami Surakka | <a href="https://kirjailija.blog" target="_blank"
                                                            rel="noopener noreferrer">
                                    https://kirjailija.blog
                                </a>
                                </p>

                                <p style={ { fontSize: "80%" } }>
                                    Tilastot perustuvat Finnan avoimeen tietokantaan.
                                    Lisätiedot: <a href="https://finna.fi" target="_blank"
                                                   rel="noopener noreferrer">https://finna.fi</a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const getPublishers = (publishers, filters) => {
    return Collection.from(publishers)
        .filters(filters)
        .occurrences(filters);
};

const mapStateToProps = state => {
    return {
        publishers: getPublishers(state.publishers, state.filters),
        activeKeywords: state.filters.keywords,
    }
};

export default connect(mapStateToProps)(App);
