import React from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CategoryFilter extends React.Component {
    render() {
        const categories = this.props.categories;

        const buttons = categories.map(category => {
            const variant = category.selected ? "primary" : "secondary";
            const icon = category.selected ? faCheckSquare : faSquare;
            const label = category.label;

            return (
                <Button key={ label }
                        variant={ variant }
                        onClick={ () => this.props.onCategoryToggle(category) }
                        className="m-1"
                >
                    <FontAwesomeIcon icon={ icon } className="mr-2"/>
                    { label }
                </Button>
            )
        });

        return (
            <Card>
                <Card.Body>
                    <h4>Filteröi kategorian perusteella</h4>

                    { buttons }
                </Card.Body>
            </Card>
        )
    }
}

const getCategories = (publishers, filters) => {
    let categories = {};

    publishers
        .flatMap(publisher => publisher.statistics.categories)
        .forEach(category => {
            if (category.searchable === 0) return;

            const cat = categories[category.label] || {
                label: category.label,
                value: 0,
                selected: filters.categories.includes(category.label)
            };

            cat.value += category.value;

            categories[category.label] = cat;
        });

    return Object.values(categories).sort((c1, c2) => {
        return c2.value - c1.value;
    });
};

const mapStateToProps = state => {
    return {
        categories: getCategories(state.publishers, state.filters)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCategoryToggle: category => {
            dispatch({
                type: "TOGGLE_CATEGORY",
                category,
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);