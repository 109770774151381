import React from 'react';

class Keyword extends React.Component {
    render() {
        if (this.props.value >= this.props.minValue) {
            const fontSize = (this.props.value / this.props.maxValue) * 100;

            if (fontSize < 5) return null;

            const style = {
                fontSize: fontSize + "%"
            };

            const className = "wordcloud-word " + this.props.className || "";

            return (
                <span className={ className } style={ style }>{ this.props.label }</span>
            )
        }

        return null;
    }
}

class KeywordChart extends React.Component {
    render() {
        if (!Array.isArray(this.props.data)) return (
            <div>Virheellinen tietorakenne.</div>
        );

        const data = this.props.data;
        const activeKeywords = this.props.activeKeywords;
        const maxValue = Math.max(...data.map(o => o.value));
        const minValue = Array.from(data).sort((o1, o2) => o2.value - o1.value).slice(0, 50).slice(-1)[0].value;

        const words = data.map(item => (
                <Keyword key={ item.label }
                         value={ item.value }
                         label={ item.label }
                         maxValue={ maxValue }
                         minValue={ minValue }
                         className={ activeKeywords.includes(item.label) ? "active" : "" }
                />
            )
        );

        return (
            <div className="wordcloud">
                { words }
            </div>
        )
    }
}

export default KeywordChart;
