import LabelledArray from "./LabelledArray";

class Collection extends Array {
    _includes(n, h) {
        if (!(n instanceof String || typeof n === 'string')) {
            throw new Error("Needle must be a string, " + typeof n + " given.");
        }

        if (!(h instanceof String || typeof h === 'string')) {
            throw new Error("Haystack must be a string, " + typeof h + " given.");
        }

        return h.toLowerCase().includes(n.toLowerCase());
    };

    _intersects(needle, haystack) {
        const self = this;

        if (needle instanceof Array) {
            return needle.some(n => {
                return haystack.some(h => {
                    return self._includes(n, h);
                });
            });
        }

        return haystack.some(h => self._includes(needle, h));
    }

    _reduce(needle, haystack) {
        const self = this;

        return haystack.reduce((a, v) => {
            if (self._includes(needle, v.label)) {
                return a + v.value;
            }

            return a;
        }, 0);
    }

    filters(filters) {
        const self = this;

        return this.filter(item => {
            if (filters.categories.length > 0) {
                if (!self._intersects(filters.categories, LabelledArray.from(item.statistics.categories).labels())) {
                    return false;
                }
            }

            if (filters.keywords.length > 0) {
                if (!self._intersects(filters.keywords, LabelledArray.from(item.statistics.keywords).labels())) {
                    return false;
                }
            }

            return true;
        });
    }

    occurrences(filters) {
        const self = this;

        return this.map(item => {
            let categoryOccurrences = 0;
            let keywordOccurrences = 0;
            let weights = 0;

            if (filters.categories.length > 0) {
                weights++;

                filters.categories.forEach(f => {
                    categoryOccurrences += self._reduce(f, item.statistics.categories);
                });
            }

            if (filters.keywords.length > 0) {
                weights++;

                filters.keywords.forEach(f => {
                    keywordOccurrences += self._reduce(f, item.statistics.keywords);
                });
            }

            return {
                ...item,
                occurrences: (categoryOccurrences + keywordOccurrences) / weights
            };
        });
    }
}

export default Collection;