import React from 'react';
import Table from 'react-bootstrap/Table'
import { Button } from "react-bootstrap";
import Profile from "./Profile";

class ResultsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            publisher: null
        };
    }

    openModal = (e, publisher) => {
        e.target.blur();

        this.setState({ publisher });
    };

    closeModal = () => {
        this.setState({
            publisher: null
        })
    };

    calculateRelevance = (publisher) => {
        return (publisher.occurrences > 0 ? publisher.occurrences / publisher.book_count : 1) * 100;
    };

    render() {
        const publisher = this.state.publisher;
        const activeKeywords = this.props.activeKeywords;

        const profiles = this.props.publishers
            .sort((p1, p2) => this.calculateRelevance(p2) - this.calculateRelevance(p1))
            .map((publisher) => {
                const relevance = this.calculateRelevance(publisher);

                return (
                    <tr key={ publisher.slug }>
                        <td>
                            <Button variant="link" onClick={ (e) => this.openModal(e, publisher) }>
                                { publisher.name }
                            </Button>
                        </td>
                        <td>
                            <a href={ "http://" + publisher.url } target="_blank" rel="noopener noreferrer">{ publisher.url }</a>
                        </td>
                        <td>{ publisher.book_count }</td>
                        <td>{ (relevance + "").substr(0, 4) } %</td>
                    </tr>
                )
            });

        return (
            <>
                <Profile { ...publisher } activeKeywords={ activeKeywords } show={ publisher != null } closeModal={ this.closeModal }/>

                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>Nimi</th>
                        <th>Verkkosivut</th>
                        <th className="col-2">Kirjoja</th>
                        <th className="col-2">Osuvuus</th>
                    </tr>
                    </thead>
                    <tbody>
                    { profiles }
                    </tbody>
                </Table>
            </>
        )
    }
}

export default ResultsTable;