import React from 'react';
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import CategoryChart from "./charts/CategoryChart";
import KeywordChart from "./charts/KeywordChart";
import YearlyChart from "./charts/YearlyChart";

class Profile extends React.Component {
    render() {
        if (!this.props.name) {
            return (
                <></>
            )
        }

        const name = this.props.name;
        const url = this.props.url;
        const description = this.props.description;
        const book_count = this.props.book_count;
        const owner = this.props.owner ? <p><strong>Omistaja:</strong> { this.props.owner.name }</p> : '';

        const categories = this.props.statistics.categories;
        const keywords = this.props.statistics.keywords;
        const yearly = this.props.statistics.yearly;

        const activeKeywords = this.props.activeKeywords;

        return (
            <Modal size="lg" centered show={ true } onHide={ this.props.closeModal }>
                <Modal.Body>
                    <h3>{ name } ({ book_count } kirjaa)</h3>

                    { owner }

                    <p>{ description }</p>

                    <p>
                        <strong>Verkkosivut: </strong>
                        <a href={ "http://" + url } target="_blank" rel="noopener noreferrer">{ url }</a>
                    </p>

                    <Tabs defaultActiveKey="categories" id={ this.props.name }>
                        <Tab title="Kategoriat" eventKey="categories">
                            <CategoryChart data={ categories }/>
                        </Tab>

                        <Tab title="Avainsanat" eventKey="keywords">
                            <KeywordChart data={ keywords } activeKeywords={ activeKeywords } />
                        </Tab>

                        <Tab title="Vuositilastot" eventKey="yearly">
                            <YearlyChart data={ yearly }/>
                        </Tab>
                    </Tabs>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={ this.props.closeModal }>Sulje</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default Profile;