import React from "react";
import { Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import LabelledArray from "../helpers/LabelledArray";

class KeywordFilter extends React.Component {
    render() {
        return (
            <Card>
                <Card.Body>
                    <h3>Filteröi avainsanan perusteella</h3>

                    <Form>
                        <Form.Group controlId="keywordSearch">
                            <Typeahead id="searchInput" labelKey="search" multiple
                                       onChange={ this.props.onChange }
                                       options={ this.props.keywords }
                                       placeholder="Kirjoita avainsana"
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}

const getKeywords = (publishers, filters) => {
    return new LabelledArray(...publishers.flatMap(publisher => {
        return publisher.statistics.keywords;
    })).sortDesc().labels();
};

const mapStateToProps = state => {
    return {
        keywords: getKeywords(state.publishers, state.filters),
        selected: state.filters.keywords,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChange: value => {
            dispatch({
                type: "SET_KEYWORDS",
                value,
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordFilter);