class LabelledArray extends Array {
    #filtered = false;

    unique() {
        let uniques = {};

        this.forEach(item => {
            const i = uniques[item.label] || {
                label: item.label,
                value: 0,
            };

            i.value += item.value;

            uniques[item.label] = i;
        });

        this.#filtered = true;

        return new LabelledArray(...Object.values(uniques));
    }

    labels() {
        return (this.#filtered ? this : this.unique()).map(item => item.label);
    }

    sortDesc() {
        return (this.#filtered ? this : this.unique()).sort((i1, i2) => i2.value - i1.value);
    }
}

export default LabelledArray;