import { combineReducers, createStore } from 'redux'

const initialState = {
    publishers: [],
    filters: {
        categories: [],
        keywords: [],
    },
};

function publishers(state = [], action) {
    if (action.type === "ADD_PUBLISHER") {
        return state.concat(action.data);
    }

    return state;
}

function filters(state = {}, action) {
    if (action.type === "TOGGLE_CATEGORY") {
        const label = action.category.label;
        let newCategories = [];

        if (state.categories.includes(label)) {
            newCategories = state.categories.filter(l => l !== label);
        } else {
            newCategories = state.categories.concat(label);
        }

        return Object.assign({}, state, {
            categories: newCategories
        });
    }

    if (action.type === "SET_KEYWORDS") {
        return Object.assign({}, state, {
            keywords: action.value
        });
    }

    return state;
}

const reducer = combineReducers({
    publishers,
    filters,
});

const store = createStore(reducer, initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;