import React from 'react';
import Chart from 'react-apexcharts';

class YearlyChart extends React.Component {
    render() {
        if (!Array.isArray(this.props.data)) return (
            <div>Virheellinen tietorakenne.</div>
        );

        const data = this.props.data;

        const options = {
            xaxis: {
                categories: data.map(item => item.label),
            },
            chart: {
                animations: {
                    enabled: false
                },
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            }
        };

        const series = [
            {
                name: "Julkaisut",
                data: data.map(item => item.value),
            }
        ];

        return (
            <>
                <Chart
                    options={options}
                    series={series}
                    type="line"
                />
            </>
        )
    }
}

export default YearlyChart;
